@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400; /* Medium */
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500; /* Medium */
  src: url("./assets/fonts/OpenSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 600; /* Semibold */
  src: url("./assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700; /* Bold */
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
}

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

* {
  font-family: "Google Sans", Arial, sans-serif;
  font-weight: 400;
}

.font-medium {
  font-family: "Google Sans", Arial, sans-serif;
  font-weight: 500;
}

/* Semibold */
.font-semibold {
  font-family: "Google Sans", Arial, sans-serif;
  font-weight: 600;
}

/* Bold */
.font-bold {
  font-family: "Google Sans", Arial, sans-serif;
  font-weight: 700;
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.hide-spinner::-webkit-inner-spin-button,
.hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-spinner {
  -moz-appearance: textfield; /* For Firefox */
}

.dark {
  background-color: #0d0e11;
}

* {
  outline: none;
}
